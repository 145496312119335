<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="folders-edit">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <div class="formField">
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.name')"
              v-model="editName"
              :error-messages="editNameErrors"
              @input="$v.editName.$touch()"
              @blur="$v.editName.$touch()"
              type="text"
            >
            </v-text-field>
          </div>
          <div class="formField">
            <v-checkbox
              v-bind="propsFormFields"
              :label="$t('documents.private')"
              v-model="editPrivate"
              @change="$v.editPrivate.$touch()"
              @blur="$v.editPrivate.$touch()"
            ></v-checkbox>
          </div>
          <div class="formField">
            <v-autocomplete
              v-bind="propsFormFields"
              v-model="editOwnerId"
              :items="users"
              item-value="id"
              :label="$t('users.owner')"
              @input="$v.editOwnerId.$touch()"
              @blur="$v.editOwnerId.$touch()"
            >
              <template v-slot:selection="{ item }">
                <span>{{ item.name }} {{ item.surnames }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.name }} {{ item.surnames }}</v-list-item-title
                  >
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </v-form>
      </div>

      <div class="d-flex">
        <v-btn
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formRemove"
          :to="{ name: 'folders-delete', params: { id: objectId } }"
          data-cy="button-remove"
          >{{ $t("common.remove") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :to="{ name: 'folders-edit-permissions', params: { id: objectId } }"
          data-cy="button-permissions"
          >{{ $t("folders.permissions.button") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpdate"
          data-cy="button-update"
          >{{ $t("common.update") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "FoldersEdit",
  mixins: [ComponentStatus],
  components: {},
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    changed: false,

    // Edit proxy form data
    editFolderId: "",
    editName: "",
    editPrivate: false,
    editOwnerId: null,
  }),
  validations: {
    editName: {},
    editPrivate: {},
    editOwnerId: {},
  },
  computed: {
    users() {
      return this.$store.getters["users/readAll"];
    },
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formRemove() {
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      return errors;
    },
    // 2) When the folder id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the folder data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    folder() {
      return this.$store.getters["folders/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the folder id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.editFolderId = this.objectId;
          this.$store
            .dispatch("folders/readById", this.objectId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    // 4) In case we load new folder data (and we are not in the middle of
    // editing) we update our "proxy" form editable variables to reflect
    // what we have just loaded.
    folder: {
      immediate: true,
      handler: function (newValue /* , oldValue */) {
        this.refreshFormData(newValue);
      },
    },
  },
  created() {},
  methods: {
    inputChanged() {
      this.changed = true;
    },
    refreshFormData(value) {
      let newValue = this.folder;
      if (value) {
        newValue = value;
      }
      if (!this.changed) {
        this.editName = newValue.name;
        this.editPrivate = newValue.private;
        this.editOwnerId = newValue.ownerId;
      }
    },
    clickUpdate() {
      this.setStatusUpdating();
      let payload = {
        id: this.editFolderId,
        name: this.editName,
        private: this.editPrivate,
        ownerId: this.editOwnerId,
      };
      this.$store
        .dispatch("folders/update", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          // this.refreshFormData();
        })
        .catch((/* error */) => {
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
